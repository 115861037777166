import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, TextField, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { useEnqueueSnackbar } from '../../../hooks/useEnqueueSnackbar';
import { clearValetActivity } from '../../../state/slices/shiftSession/shiftSession';
import ValetParkingService from '../../../services/ValetParkingService';
import apiClient from '../../../auth/apiClient';
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const CashieredDevicePerformValetRetrievalActivity = ({ classes, onCancel }) => {
    const dispatch = useDispatch();
    const enqueueSnackbar = useEnqueueSnackbar();
    const valetParkingService = new ValetParkingService(apiClient);
    const useCoreEntitySlice = useCoreEntityContext();    

    const entityID = useSelector((state) => useCoreEntitySlice ? state.coreEntities.ContextID : state.entities?.ContextID);
    const ticketNumber = useSelector((state) => state.shiftSession?.valetActivity?.ticketNumber);
    const shiftID = useSelector((state) => state.shiftSession.shiftID);

    const notificationStyle = useSelector((state) => {
        return {
            small: state.shiftSession.smallScreen,
            toastLocation: state.shiftSession.toastLocation,
        };
    });

    const triggerEnqueueSnackBar = (msg, variant, tag) => {
        enqueueSnackbar(msg, {
            variant: variant,
            tag: tag,
            anchorOrigin: notificationStyle.toastLocation,
            fullwidth: notificationStyle.small,
        });
    };

    // Since, no API call is made to fetch employees, hardcoding the employees
    const employees = [{ employeeID: 1, employeeName: "unassigned" }];

    // Form handling
    const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            ticketNumber: ticketNumber,
            retrievedByEmployee: 1
        },
        mode: "all"
    });

    const handleCancel = () => {
        dispatch(clearValetActivity());
        onCancel();
    };

    const onSubmit = async (data) => {
        try {
            let retrievalRequest = {
                entityID,
                ticketNumber,
                //Setting retrievedBy to null as default selected value is not actual and is only for UI purpose.
                retrievedBy: null,
                shiftID: shiftID
            };
            await valetParkingService.submitRetrieval(retrievalRequest);
            triggerEnqueueSnackBar("Ticket successfully retrieved", "success");
            handleCancel();
        } catch (error) {
            triggerEnqueueSnackBar("Error occurred while processing retrieval", "error");
        }
    };

    return (
        <div className={classes.step} data-testid="valet-retrieval-step">
            <div className={classes.valetFormContent}>
                <Typography variant="h4" component="h1" className={clsx("retrieval-header", classes.header)}>
                    Retrieve Ticket
                </Typography>
                <TextField 
                    variant="outlined"
                    margin="normal"
                    id="ticketNumber"
                    label="Ticket Number"
                    value={ticketNumber}
                    disabled
                    fullWidth
                />
                <Controller 
                    name="retrievedByEmployee"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Retrieved By Employee"
                            fullWidth
                            select
                            variant="outlined"
                            margin="normal"
                            error={!!errors.retrievedByEmployee}
                            helperText={errors.retrievedByEmployee?.message}
                            data-testid="retrievedByEmployee-select"
                        >
                            {employees.map(emp => (
                                <MenuItem key={emp.employeeID} value={emp.employeeID}>
                                    {emp.employeeName}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
                <div>
                    <Button 
                        className={clsx("cancelBtn", classes.buttonRight)}
                        name="cancel"
                        variant="contained"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                        data-testid="cancelBtn"
                    >
                        Cancel
                    </Button> 
                    <Button
                        color="primary"
                        name="submit"
                        type="submit"
                        variant="contained"
                        className={clsx("saveBtn", classes.buttonRight)}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isSubmitting}
                        data-testid="saveBtn"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    ); 
};

CashieredDevicePerformValetRetrievalActivity.defaultProps = {
    classes: {},
    onCancel: () => { }
};

CashieredDevicePerformValetRetrievalActivity.propTypes = {
    classes: PropTypes.object,
    onCancel: PropTypes.func
};

export default CashieredDevicePerformValetRetrievalActivity;
