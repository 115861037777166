import React, { useState, useEffect } from "react";

/* Components */
import { Button, Typography, Grid } from "@material-ui/core";

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays as calendarIcon,
  faBarcode as barcodeIcon,
  faTicket as ticketIcon,
  faBellExclamation as requestIcon,
  faCar as retrievalIcon,
  faCircleCheck as deliveryIcon
} from "@fortawesome/pro-regular-svg-icons";

/* Style */
import clsx from "clsx";

/* State */
import useCashierOffers from "../Utilities/useCashierOffers";
import { TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

/* Hooks */
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

/* Services */
import SettingsService from "../../../services/SettingsService";
import apiClient from "../../../auth/apiClient";

const CashieredDeviceSelectEntryType = ({
  classes,
  onSelectOfferClick,
  onScanCredentialClick,
  onScanTicketClick,
  isValetHandheldSelected,
}) => {

  const settingService = new SettingsService(apiClient);
  const enqueueSnackbar = useEnqueueSnackbar();

  const [showRetrieval, setShowRetrieval] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [visibleValetOptions, setVisibleValetOptions] = useState([]);

  const possibleValetOptions = [
    {
      testId: "arrival",
      className: classes.arrivalChoiceButton,
      icon: ticketIcon,
      title: "Arrival",
      subtitle: "Scan valet ticket to arrive a car.",
      onClick: () => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.ARRIVAL),
      isVisible: true,
    },
    {
      testId: "request",
      className: classes.requestChoiceButton,
      icon: requestIcon,
      title: "Pay Request",
      subtitle: "Scan valet ticket to request a car.",
      onClick: () => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.REQUEST),
      isVisible: true,
    },
    {
      testId: "retrieval",
      className: classes.retrievalChoiceButton,
      icon: retrievalIcon,
      title: "Retrieval",
      subtitle: "Scan valet ticket to retrieve a car.",
      onClick: () => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.RETRIEVAL),
      isVisible: showRetrieval,
    },
    {
      testId: "delivery",
      className: classes.deliveryChoiceButton,
      icon: deliveryIcon,
      title: "Delivery",
      subtitle: "Scan valet ticket to deliver a car.",
      onClick: () => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.DELIVERY),
      isVisible: showDelivery,
    },
  ];

  const { facilityID: entityID } = useCurrentFacility(); 

  const { selectedOffers } = useCashierOffers();
  const hasNoOffers = selectedOffers === undefined || selectedOffers?.length < 1;

  const setValetModulesVisibility = async () => {
    try {
      let showRetrievalSettingName = "setting.valetarea.showretrieval";
      let showDeliverySettingName = "setting.valetarea.showdelivery";
      
      let result = await settingService.getSettings(entityID);

      let isRetrievalVisible = result.data?.[showRetrievalSettingName]?.toLowerCase() === "true";
      setShowRetrieval(isRetrievalVisible);
      
      let isDeliveryVisible = result.data?.[showDeliverySettingName]?.toLowerCase() === "true";
      setShowDelivery(isDeliveryVisible);
    
    } catch (error) {
      enqueueSnackbar("Unable to fetch valet modules visibility settings", {
        variant: "error",
        tag: "getVisibilitySettingError"
      });
    }
  }

  useEffect(() => {
    if (isValetHandheldSelected) {
      setValetModulesVisibility();
    }
  }, []);
 
  useEffect(() => {
    if (isValetHandheldSelected) {
      const visibleOptions = possibleValetOptions.filter((option) => option.isVisible !== false);
      setVisibleValetOptions(visibleOptions);
    }
  }, [showRetrieval, showDelivery]);

  return (
    <>
      {!isValetHandheldSelected && (
        <div className={classes.step} data-testid="start-step">
          {!hasNoOffers && (
            <Button
              data-testid="selectOffer"
              className={clsx(
                classes.entryChoiceButton,
                classes.offersChoiceButton
              )}
              disabled={hasNoOffers}
              onClick={onSelectOfferClick}
            >
              <FontAwesomeIcon
                className={classes.entryChoiceButtonIcon}
                icon={calendarIcon}
              ></FontAwesomeIcon>
              <div className={classes.entryChoiceButtonText}>
                <Typography variant="h5" component="div">
                  Offers
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Select from a list of pre-pay events / offers.
                </Typography>
              </div>
            </Button>
          )}
          <Button
            data-testid="scanCredential"
            className={clsx(
              classes.entryChoiceButton,
              classes.credentialChoiceButton
            )}
            onClick={onScanCredentialClick}
          >
            <FontAwesomeIcon
              className={classes.entryChoiceButtonIcon}
              icon={barcodeIcon}
            ></FontAwesomeIcon>
            <div className={classes.entryChoiceButtonText}>
              <Typography variant="h5" component="div">
                Scan Credential
              </Typography>
              <Typography variant="subtitle1" component="div">
                Scan a credential presented by the customer.
              </Typography>
            </div>
          </Button>
        </div>
      )}
      {isValetHandheldSelected && (
        <div className={classes.valetEntryTypeStep} data-testid="valet-start-step">
          {/* 
              Normal View:
                  Grid configured with three rows and three columns.
                  To meet the requirement needs, second row first column made empty.
                  Handled 2 Scenarios : 
                  1. In case of 4 modules need to show, 3 module will be shown in First
                  row and 4th module will be shown in 2nd row and 2nd column.
                  2. In case of less than 4 modules all the module will be shown in 
                  First row and 2nd row will be empty.
              Mobile View: 
                  Grid configured to show rows each with single column 
                  so that all modules will be shown vertically      
          */}
          <Grid container spacing={3}>
            {visibleValetOptions.map((config, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={4} className={classes.gridItem}>
                    <Button
                      data-testid={config.testId}
                      className={clsx(classes.valetEntryChoiceButton, config.className)}
                      onClick={config.onClick}
                    >
                      <FontAwesomeIcon
                        className={classes.entryChoiceButtonIcon}
                        icon={config.icon}
                      />
                      <div className={classes.valetEntryChoiceButtonText}>
                        <Typography variant="h5" component="div">
                          {config.title}
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                          {config.subtitle}
                        </Typography>
                      </div>
                    </Button>
                  </Grid>
                  {/* In case of 4 button, the 4th button need to be shown in 2nd row and 2nd column.
                      So Empty button need to be added. */}
                  {visibleValetOptions.length === 4 && index === 2 && (
                    <Grid item xs={12} sm={4} className={clsx(classes.gridItem, classes.hideInMobile)}></Grid>
                  )}
                </React.Fragment>
            ))}
            {/* Empty buttons need to be shown in case of total button count is 3 or 2 */}
            {[...Array(4 - visibleValetOptions.length)].map((_, index) => (
              <Grid key={index} item xs={12} sm={4} className={clsx(classes.gridItem, classes.hideInMobile)}></Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};
export default CashieredDeviceSelectEntryType;
