import { useEffect } from "react";

/* Utilities */
import useHubContext from "../../../hooks/useHubContext";

/* State */
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  updateCreditDisplay,
  updateTransactionStatus,
} from "../../../state/slices/shiftSession/shiftSession";
import { FindEntity } from "../../../state/slices/entities";
import useRoamingTerminal from "../../../hooks/useRoamingTerminal";
import { selectEntityById } from "../../../state/slices/CoreEntity";
import { useCoreEntityContext } from "../../../hooks/useCoreEntitySlice";

/* Services */
import apiClient from "../../../auth/apiClient";
import CashierService from "../../../services/CashierService";
import useCurrentUser from "../../../hooks/useCurrentUser";

import { ENTITY_TYPE } from "../../../constants";

const cashierService = new CashierService(apiClient);

const usePos = ({
  posTerminalID,
  cashierID,
  facilityID,
  onTransactionChanged,
}) => {
  const useCoreEntitySlice = useCoreEntityContext();
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const roamingCCTerminal = useRoamingTerminal(posTerminalID) ?? {};
  const shiftID = useSelector((state) => state.shiftSession.shiftID);
  const credential = useSelector(
    (state) => state.shiftSession.transaction.credential
  );
  const transactionRef = useSelector(
    (state) => state.shiftSession.transaction.reference
  );
  const creditDisplayLines = useSelector(
    (state) => state.shiftSession.transaction.creditDisplayLines
  );
  const cashieredDeviceID = useSelector(
    (state) => state.shiftSession.cashieredDeviceID
  );
  const entityType = useSelector((state) => {
    const entity = useCoreEntitySlice ? selectEntityById(state, facilityID) : FindEntity(state.entities?.EntityList ?? [], facilityID);
    return entity?.typeid;
  }, shallowEqual);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (PortalHubConnected && transactionRef) {
      portalHub.subscribe("CASHIER_TRANSACTION_CHANGED", (data) => {
        const dataJson = JSON.parse(data);
        const message = JSON.parse(dataJson.Message);
        const messageTransactionRef = message.TransactionReference;

        if (dataJson.EntityID === facilityID && messageTransactionRef === transactionRef) {
          dispatch(updateTransactionStatus({ status: message.Status }));
          dispatch(updateCreditDisplay({
              creditDisplayLines: {
                displayLine1: message.DisplayLines[0]?.trim() ?? "",
                displayLine2: message.DisplayLines[1]?.trim() ?? "",
              },
            }));

          if (onTransactionChanged) onTransactionChanged(message);
        }
      });

      return () => {
        portalHub.unsubscribe("CASHIER_TRANSACTION_CHANGED");
      };
    }
  }, [PortalHubConnected, transactionRef]);

  const processCreditPayment = async (amount) => {
    const response = await cashierService.processCreditPayment({
      amount,
      cashierID,
    });

    if (response.data.status !== ResponseTypes.Unset) {
      dispatch(updateCreditDisplay({
          creditDisplayLines: {
            displayLine1: response.data.displayLines[0]?.trim() ?? "",
            displayLine2: response.data.displayLines[1]?.trim() ?? "",
          },
      }));
    } else {
      if (response.data.resultMessage) {
        throw new Error(response.data.resultMessage);
      } else {
        throw new Error(
          `${response.data.displayLines[0] ?? ""} ${response.data
            .displayLines[1] ?? ""}`
        );
      }
    }
  };

  const processCashPayment = async (amountDue, amountPaid) => {
    await cashierService.processCashPayment({
      amountDue,
      amountPaid,
      cashierID,
    });
  };

  const startTransaction = async (poeOffer, parkingTransactionID) => {
    const transactionRequest = {
      cashierID,
      facilityID,
      terminalSerialNo: roamingCCTerminal.terminalSerialNo,
      paymentDetails: {
        selectedOffer: poeOffer,
        parkingTransactionID,
        shiftID,
        scannedCredential: credential,
        ticketID: credential?.ticketID,
        deviceName: roamingCCTerminal.displayName ?? (entityType === ENTITY_TYPE.ValetArea ? "Valet Cashier" : "Roaming Cashier"),
        attendantName: currentUser.Username,
        cashieredDeviceID,
      },
    };

    const response = await cashierService.startTransaction(transactionRequest);
    return response.data;
  };

  const complete = async () => {
    await cashierService.completeTransaction(cashierID);
  };

  const cancelCredit = async () => {
    if (!transactionRef) {
      console.log(
        "There is no transaction reference associated to the current credit transaction"
      );
      return;
    }

    const response = await cashierService.cancelCredit({
      cashierID,
    });
    return response.data;
  };

  const cancelTransaction = async () => {
    await cashierService.cancelTransaction({ cashierID });
  };

  const printReceipt = async () => {
    await cashierService.printReceipt(cashierID);
  };

  return {
    startTransaction,
    processCreditPayment,
    processCashPayment,
    cancelCredit,
    cancelTransaction,
    complete,
    printReceipt,
    displayLines: creditDisplayLines,
    transactionRef,
  };
};

export default usePos;

export const ResponseTypes = {
  Unset: 0,
  Pending:15,  
  InProgress: 1,
  AwaitingPaymentCompleted: 2,
  Complete: 3,
  Failed: 4,
  Cancelled: 5,
  Declined: 6,
};
